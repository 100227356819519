import LandingPageLink from "./landingPageLink";
import { EventLandingPage } from "@types";

interface Props {
  landingPages: EventLandingPage[];
}

export default function CartNotificationUpsell({ landingPages }: Props) {
  return (
    <div className="flex flex-col gap-4 md:gap-2 mt-2">
      <p className="text-sm font-semibold text-center">
        Explore more from{" "}
        {landingPages
          .map((v, i) => {
            return landingPages.length === 1
              ? v.title
              : landingPages.length === i + 2
              ? `${v.title} & `
              : landingPages.length > i + 1
              ? `${v.title}, `
              : v.title;
          })
          .join("")}
      </p>
      {landingPages.map((landingpage) => (
        <LandingPageLink
          key={landingpage.id}
          slug={landingpage.url}
          image={landingpage.images.header}
          name={landingpage.title}
          showNameOnMobile={landingPages.length > 1}
        />
      ))}
      <p className="text-center text-sm md:mt-2">or</p>
    </div>
  );
}
