import {
  ExclamationTriangleIcon,
  FireIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { joinClassNames } from "@utils/helpers";
import { TimesType } from "../../EventPage/types";
import ShowingCountDown from "../../EventPage/components/showingCountDown";
import { EventShowing, EventShowingsMsgs } from "@types";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import ShowingStatusToolTip from "@features/eventListMode/components/showingStatusToolTip";
import { KeyedMutator } from "swr";
import { motion } from "framer-motion";

interface Props {
  times: TimesType[];
  selectedTime?: EventShowing;
  mutate: KeyedMutator<EventShowingsMsgs>;
  handleSelectTime: (id: number | null) => void;
  bookedTimeTickets: (id: number) => number | undefined;
}

export default function EventTimePicker({
  times,
  selectedTime,
  handleSelectTime,
  bookedTimeTickets,
  mutate,
}: Props) {
  return (
    <div
      data-testid="showingtime"
      role="list"
      className={
        selectedTime
          ? "relative z-10 inline-block w-full sm:w-fit h-full align-top"
          : "mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3"
      }
    >
      {times.map((time) => (
        <motion.div
          animate={
            selectedTime && selectedTime.id === time.id
              ? { opacity: [0, 1], x: ["1rem", "0rem"] }
              : { opacity: 1, x: 0 }
          }
          whileTap={{
            scale: 0.95,
          }}
          key={time.id}
          className={joinClassNames(
            selectedTime && selectedTime.id !== time.id ? "hidden" : "",
            "col-span-1 relative w-full",
            time.availability?.selectable && times.length > 1
              ? "cursor-pointer"
              : ""
          )}
        >
          <div
            onClick={() => {
              if (!time.availability?.selectable) return;

              handleSelectTime(
                selectedTime?.id === time.id && times.length > 1
                  ? null
                  : time.id
              );
            }}
            className={joinClassNames(
              "flex gap-1 flex-col justify-between px-4 py-2 rounded-md border shadow border-gray-100 w-full",
              selectedTime?.id === time.id
                ? "bg-indigo-50 ring-2 ring-indigo-500"
                : time.availability?.selectable
                ? "bg-white"
                : "bg-gray-100 cursor-not-allowed"
            )}
          >
            <div className="flex flex-col justify-between gap-1 text-black w-full">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-1 truncate">
                  {time?.availability?.icon === "shield" ? (
                    <ShieldExclamationIcon className="h-5 w-5 text-gray-500 shrink-0" />
                  ) : time?.availability?.icon === "warning" ? (
                    <ExclamationTriangleIcon className="h-5 w-5 text-orange-500 shrink-0" />
                  ) : time?.availability?.icon === "fire" ? (
                    <FireIcon className="h-6 w-6 text-orange-400 shrink-0" />
                  ) : time?.availability?.icon === "closed" ? (
                    <XCircleIcon className="h-5 w-5 text-red-600 shrink-0" />
                  ) : (
                    <></>
                  )}
                  <p className="font-medium text-black truncate w-fit">
                    {time.displayDate ? time.displayDate : time.name}
                  </p>
                </div>
                {selectedTime?.id === time.id && times.length > 1 ? (
                  <div className="flex gap-1 items-center shrink-0">
                    <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                    <span className="text-gray-500 text-sm font-medium">
                      Change time
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {time.displayName ? (
                <span className="text-gray-500">{time.displayName}</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          {!time.availability?.selectable && (
            <div
              className={joinClassNames(
                "absolute -right-2 -top-4 flex items-center gap-1 h-6 w-auto px-2 py-1 rounded-full font-medium text-sm",
                time.availability?.bgColor ?? ""
              )}
            >
              {time.seconds && time.seconds > 0 ? (
                <>
                  <ShowingCountDown
                    ttl={time.seconds}
                    title={time.availability?.title ?? ""}
                    textColor={time.availability?.textColor}
                    mutate={mutate}
                  />
                </>
              ) : (
                <span className={time.availability?.textColor}>
                  {time.availability?.title}
                </span>
              )}
              <ShowingStatusToolTip
                title={time.availability?.title ?? ""}
                description={time.availability?.description ?? ""}
                statusId={time.statusId}
                accessType={time.accessType}
              />
            </div>
          )}
          {bookedTimeTickets(time.id) ? (
            <span className="absolute -right-2 -top-4 block h-5 w-auto px-2 pt-0.5 rounded-lg bg-indigo-100 text-indigo-800 text-xs text-center">
              {bookedTimeTickets(time.id)} in cart
            </span>
          ) : (
            <></>
          )}
        </motion.div>
      ))}
    </div>
  );
}
