"use client";

import { Basket } from "@features/cartDetails/types";
import { EventData, EventShowing, EventShowingsMsgs } from "@types";
import { AccessType, ShowingStatus } from "@utils/enums";
import { joinClassNames } from "@utils/helpers";
import { useIsInViewport } from "@utils/hooks";
import { format, parseISO } from "date-fns";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { KeyedMutator } from "swr";
import EventAreaPicker from "../../EventPage/components/eventAreaPicker";
import { AvailabilityType, TimesType } from "../../EventPage/types";
import EventDatePicker from "./eventDatePicker";
import EventTimePicker from "./eventTimePicker";
interface Props {
  showingData: EventShowingsMsgs;
  basket: Basket | undefined;
  event: EventData;
  getPriceRange: ({
    pricesectionIds,
    groupedPricesectionIds,
  }: {
    pricesectionIds?: number[];
    groupedPricesectionIds?: number[];
  }) => { min: number; max: number; isEmpty?: boolean };
  mutate: KeyedMutator<EventShowingsMsgs>;
}

const Showings = ({
  showingData,
  basket,
  event,
  getPriceRange,
  mutate,
}: Props) => {
  const eventId = event.legacy.e_id;
  const eventVenueId = event.legacy.ev_id;
  const [times, setTimes] = useState<TimesType[]>([]);
  const [selectedShowingTime, setSelectedShowingTime] = useState<
    EventShowing | undefined
  >(undefined);
  const { isInViewport, scrollToTarget, targetRef } =
    useIsInViewport<HTMLHeadingElement>();

  const basketEvent = basket?.Events?.find((event) => event.Id === eventId);

  const bookedTimeTickets = (id: number) =>
    basketEvent?.Showings?.filter((showing) => showing.Id === id).map(
      (showing) => showing.Items.length
    )[0];

  const bookedDateTickets = (id: number): number | undefined => {
    const selectedDate = showingData?.showings.find((ud) => ud.id === id);

    if (!selectedDate) return;

    return showingData?.showings
      .filter(
        (s) =>
          s.active &&
          format(parseISO(s.date.local.start), `d MMM`) ===
            format(parseISO(selectedDate.date.local.start ?? ""), `d MMM`)
      )
      .map((v) =>
        basketEvent?.Showings?.filter((showing) => showing.Id === v.id)
          .map((showing) => showing.Items.length)
          .reduce((prev, curr) => prev + curr, 0)
      )
      .reduce((prev: any, curr) => {
        return prev + curr;
      }, 0);
  };

  const handleAccessType = (
    time: EventShowing,
    messages:
      | {
          id: number;
          name: string;
          access: string;
          type: string;
          status: number;
          message: string;
        }[]
      | undefined
  ): AvailabilityType | undefined => {
    const titleShowing =
      messages
        ?.filter((msg) => msg.type === "button")
        .find((msg) => msg.status === time.status.id)?.message ?? "";

    const titleDescription =
      messages
        ?.filter((msg) => msg.type === "message")
        .find((msg) => msg.status === time.status.id)?.message ?? "";
    if (time.status.id === ShowingStatus.POSTPONED) {
      return {
        icon: "warning",
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        title: titleShowing.trim() || "Postponed",
        description: titleDescription.trim() || "This has been postponed.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.NOTYETONSALE) {
      return {
        icon: "warning",
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        title: titleShowing.trim() || "Not on sale",
        description: titleDescription.trim() || "Not on sale.",
        selectable: false,
      };
    }

    if (
      time.status.id === ShowingStatus.SOLDOUT ||
      time.status.id === ShowingStatus.ADMINSOLDOUT
    ) {
      return {
        icon: "closed",
        bgColor: "bg-red-100",
        textColor: "text-red-600",
        title: titleShowing.trim() || "Sold out",
        description: titleDescription.trim() || "This showing is sold out.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.CANCELLED) {
      return {
        icon: "closed",
        bgColor: "bg-red-100",
        textColor: "text-red-600",
        title: titleShowing.trim() || "Cancelled",
        description: titleDescription.trim() || "This showing is cancelled.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.FREEENTRY) {
      return {
        icon: "closed",
        bgColor: "bg-green-100",
        textColor: "text-green-600",
        title: titleShowing.trim() || "Free Entry",
        description:
          titleDescription.trim() ||
          "This is a free entry event. You do not need a ticket.",
        selectable: false,
      };
    }

    if (time.status.id === ShowingStatus.AVAILABLE) {
      const title = messages
        ?.filter((msg) => msg.type === "button")
        .find((msg) => msg.access === time.access.type)?.message;

      const description = messages
        ?.filter((msg) => msg.type === "message")
        .find((msg) => msg.access === time.access.type)?.message;
      switch (time.access.type) {
        case AccessType.PUBLIC:
          return time.status.hot === 1
            ? {
                icon: "fire",
                bgColor: "bg-red-100",
                textColor: "text-red-600",
                selectable: true,
              }
            : {
                icon: "check",
                bgColor: "bg-green-100",
                textColor: "text-green-600",
                selectable: true,
              };
        case AccessType.PASSWORD:
          return {
            icon: "shield",
            bgColor: "bg-green-100",
            textColor: "text-green-600",
            selectable: true,
          };
        case AccessType.NOTYETONSALE || AccessType.NOTONSALE:
          return {
            icon: "warning",
            bgColor: "bg-orange-100",
            textColor: "text-orange-600",
            title: title
              ? title.replace(
                  "{date}",
                  time.access.date.public.local.start.formatted ??
                    format(
                      parseISO(time.access.date.public.local.start.raw),
                      `EEE d MMM yyyy h:mm aa`
                    )
                )
              : "Not on sale",
            description: description
              ? description.replace(
                  "{date}",
                  time.access.date.public.local.start.formatted ??
                    format(
                      parseISO(time.access.date.public.local.start.raw),
                      `EEE d MMM yyyy h:mm aa`
                    )
                )
              : "Not yet on sale",
            selectable: false,
          };
        case AccessType.SALESCLOSED:
          return {
            icon: "closed",
            bgColor: "bg-red-100",
            textColor: "text-red-600",
            title: title || "Sales closed.",
            description: description || "Sales closed.",
            selectable: false,
          };
        case AccessType.DOORSALES:
          return {
            icon: "warning",
            bgColor: "bg-orange-100",
            textColor: "text-orange-600",
            title: title || "Door Sales",
            description: description || "Door sales may still be available.",
            selectable: false,
          };
        case AccessType.EXPIRED:
          return {
            icon: "closed",
            bgColor: "bg-red-100",
            textColor: "text-red-600",
            title: title || "Expired",
            description: description || "The showing at this time has expired.",
            selectable: false,
          };
      }
    }
  };

  const handleSelectDate = useCallback(
    (id: number | null) => {
      if (!showingData) return;
      if (!id) {
        setSelectedShowingTime(undefined);
        setTimes([]);
        return;
      }
      const selectedDate = showingData?.showings.find((ud) => ud.id === id);
      setSelectedShowingTime(undefined);

      const times = showingData?.showings
        .filter(
          (s) =>
            s.active &&
            format(parseISO(s.date.local.start), `d MMM`) ===
              format(parseISO(selectedDate?.date.local.start ?? ""), `d MMM`)
        )
        .map((s) => ({
          id: s.id,
          name: format(parseISO(s.date.local.start), `h:mm aa`),
          displayDate: s.date.display,
          displayName: s.name,
          formattedDate: s.date.local.formatted,
          seconds:
            s.access.type === AccessType.PASSWORD
              ? s.access.date.password.secondstostart
              : s.access.date.public.secondstostart,
          startDate: s.access.date.public.local.start.formatted,
          availability: handleAccessType(s, showingData.messages),
          accessType: s.access.type,
          statusId: s.status.id,
        }));

      if (!isInViewport) {
        scrollToTarget();
      }

      const sortedTimes = times.sort((a, b) => {
        // Convert the time strings to a common format for comparison.
        const formatTime = (time: string) => {
          const [hour, minute] = time.split(":");
          const isPM =
            time.toLowerCase().includes("pm") && parseInt(hour) !== 12;
          return {
            hour: parseInt(hour),
            minute: parseInt(minute),
            isPM,
          };
        };

        const formattedA = formatTime(a.name);
        const formattedB = formatTime(b.name);

        // Compare hours first.
        if (formattedA.isPM !== formattedB.isPM) {
          return formattedA.isPM ? 1 : -1;
        }

        if (formattedA.hour !== formattedB.hour) {
          return formattedA.hour - formattedB.hour;
        }

        // If hours are the same, compare minutes.
        return formattedA.minute - formattedB.minute;
      });

      setTimes(sortedTimes);
      if (times.length === 1 && times[0].availability?.selectable) {
        const foundTime = showingData?.showings.find(
          (ud) => ud.id === times[0].id
        );
        setSelectedShowingTime(foundTime);
      }
    },
    [showingData, isInViewport, scrollToTarget]
  );

  const handleSelectTime = (id: number | null) => {
    if (!id) {
      setSelectedShowingTime(undefined);
      return;
    }
    const foundTime = showingData?.showings.find((ud) => ud.id === id);

    setSelectedShowingTime(foundTime);
  };

  useEffect(() => {
    const uniqueShowings = Object.values(
      (showingData.showings as EventShowing[]).reduce(
        (acc, obj) => ({
          ...acc,
          [format(parseISO(obj.date.local.start), `d MMM yyyy`)]: obj,
        }),
        {} as EventShowing[]
      )
    );
    if (uniqueShowings.length === 1) {
      handleSelectDate(uniqueShowings[0].id);
    }
  }, [handleSelectDate, showingData.showings]);

  return (
    <motion.div
      className="mt-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <motion.h2
        initial={
          showingData.showings.length === 1
            ? { opacity: 0, height: 0 }
            : { opacity: 1, height: 44 }
        }
        animate={
          times.length > 0
            ? { opacity: 0, height: 0 }
            : { opacity: 1, height: 44 }
        }
        className="text-lg font-semibold text-black"
      >
        Choose a date
      </motion.h2>
      <EventDatePicker
        showings={showingData.showings}
        messages={showingData.messages}
        bookedDateTickets={bookedDateTickets}
        handleSelectDate={handleSelectDate}
      />
      <motion.h2
        ref={targetRef}
        key={selectedShowingTime?.id}
        initial={{ opacity: 0, height: 0, marginTop: 0, marginBottom: 0 }}
        animate={
          times.length > 0 && !selectedShowingTime
            ? { opacity: 1, height: "auto" }
            : { opacity: 0, height: 0 }
        }
        className={joinClassNames(
          "text-lg font-semibold text-black",
          selectedShowingTime ? "hidden" : ""
        )}
      >
        Choose a time
      </motion.h2>
      <EventTimePicker
        bookedTimeTickets={bookedTimeTickets}
        times={times}
        selectedTime={selectedShowingTime}
        handleSelectTime={handleSelectTime}
        mutate={mutate}
      />
      {times.length > 0 && selectedShowingTime ? (
        <div className="mt-6 sm:mt-4 mb-1">
          <EventAreaPicker
            key={selectedShowingTime.id}
            showing={selectedShowingTime}
            messages={showingData.messages}
            eventId={eventId}
            eventName={event.name}
            eventVenueId={eventVenueId}
            orgUid={event.organisation.uid}
            basketEvent={basketEvent}
            getPriceRange={getPriceRange}
            selectedConnectedShowings={null}
            pricesection={
              event.pricing.priceschemes?.find(
                (ps) => ps.id === selectedShowingTime.config.pricescheme.id
              )?.pricesections
            }
            analyticsConfigs={event.analyticsConfigs ?? []}
            landingPages={event.landingpages}
          />
        </div>
      ) : (
        <></>
      )}
    </motion.div>
  );
};

export default Showings;
