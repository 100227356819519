import Image from "next/image";
import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { MegaphoneIcon } from "@heroicons/react/24/solid";

interface Props {
  slug: string;
  image: string;
  name: string;
  showNameOnMobile: boolean;
}

export default function LandingPageLink({
  slug,
  image,
  name,
  showNameOnMobile,
}: Props) {
  return (
    <Link href={slug} className="relative w-full h-fit group">
      {image ? (
        <Image
          src={image.trim()}
          alt={name}
          width={460}
          height={110}
          className="object-cover w-full aspect-[46/11] rounded-xl"
        />
      ) : (
        <div className="flex items-center justify-center w-full aspect-[46/11] rounded-xl bg-indigo-50">
          <MegaphoneIcon className="text-indigo-800 w-1/2 h-1/2" />
        </div>
      )}
      <div className="max-md:hidden flex gap-1 items-center absolute bottom-2 left-2 py-1 px-2 max-w-[calc(100%-1rem)] rounded-lg text-sm font-semibold bg-indigo-100 text-indigo-800 group-hover:opacity-100 opacity-0 transition-opacity ease-in-out duration-200">
        <p className="truncate">{name}</p>
        <ArrowRightIcon className="h-4 w-4" />
      </div>
      {showNameOnMobile && (
        <p className="md:hidden text-sm font-semibold text-black pt-2">
          {name}
        </p>
      )}
    </Link>
  );
}
